var GoogleTagManagerTracker = function(options) {
  try {
    this.defaults = {
      trackerCode: '',
      ecommerce: false,
      enabled: true,
      remarketing: false,
      initTracker: false,
      currency: 'HUF',
      mode: 'dataLayer'
    };

    this.options = typeof Object.assign != 'undefined'
      ? Object.assign({}, this.defaults, options)
      : options;

    if (this.options.initTracker && (!this.options.trackerCode || !this.options.enabled)) {
      return undefined;
    }

    this.init();

    return this;
  } catch (e) {
    console.log(e);
  }
};

GoogleTagManagerTracker.prototype = {
  mode: 'dataLayer',
  init: function() {
    if (this.options.initTracker) {
      var _self = this;
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer'
            ? '&l=' + l
            : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', this.options.trackerCode);
    }

    this.mode = this.options.mode;
  },

  trackPageView: function() {},
  createCallback: function(cb) {
    var self = this
    var called = false;
    var timeout = null;

    return function(code) {
      if (code === self.options.trackerCode) {
        clearTimeout(timeout);
        if (!called) {
          called = true;
          cb(code);
        }
      } else if (!called) {
        if (timeout) {
          clearTimeout(timeout);
        }
        
        timeout = setTimeout(function(code) {
          if (!called) {
            called = true;
            cb(code);
          }
        }, 1000, code);
      }
    }
  },

  trackEvent: function(event_name, category, action, label, value, nonInteraction) {
    if (label == undefined)
      label = '';
    if (value == undefined)
      value = 0;

    if (typeof window.dataLayer != 'undefined' && this.mode == 'dataLayer') {
      var obj = {
        'event': event_name,
        'event_category': category,
        'event_action': action,
        'event_label': label,
        'value': value,
        'conversionValue': value
      };
      dataLayer.push(obj);
    } else if (typeof gtag != 'undefined') {
      gtag('event', event_name, {
        event_category: category,
        event_label: label,
        event_action: action,
        value: value
      });
    }

  },

  trackEventToAnalytics: function(event_name, category, action, label) {
    if (typeof gtag != 'undefined') {
      gtag('event', event_name, {
        event_category: category,
        event_label: label,
        event_action: action
      });
    }
  },

  setEcommerceAction: function(action) {
    ga('ec:setAction', action);
  },

  addEcommerceImpression: function(productData, impressionSource) {
    var dataObj = this.getProductObj(productData);
    dataObj.list = impressionSource;

    if (typeof window.dataLayer != 'undefined' && this.mode == "dataLayer") {
      dataLayer.push({
        currencyCode: this.options.currency,
        'ecommerce': {
          currencyCode: this.options.currency,
          impressions: [dataObj]
        }
      });
    } else if (typeof gtag != 'undefined') {
      gtag('event', 'select_content', {
        'content_type': 'product',
        'items': [dataObj]
      });
    }
  },
  addEcommerceProductView: function(productData, list) {
    var productObj = this.getProductObj(productData);

    if (typeof window.dataLayer != 'undefined' && this.mode == 'dataLayer') {
      var detail = {};
      detail.products = [productObj];
      if (list) {
        detail.actionField = {
          list: list
        };
      }

      dataLayer.push({
        currencyCode: this.options.currency,
        'ecommerce': {
          'detail': detail
        }
      });

    } else if (typeof gtag != 'undefined') {
      gtag('event', 'view_item', {
        'items': [productObj]
      });
    }
  },

  addEcommerceProductAction: function(productData, action, impressionSource) {

    if (impressionSource != undefined) {
      this.addEcommerceImpression(productData, impressionSource);
    }
    var productObj = this.getProductObj(productData);

    if (typeof window.dataLayer != 'undefined' && this.mode == 'dataLayer') {
      var eventName = action == 'remove' ? 'removeFromCart' : 'addToCart';
      var ecommerce = {};

      ecommerce.currency = this.options.currency;
      ecommerce.currencyCode = this.options.currency;
      ecommerce[action] = {
        'products': [productObj]
      }

      dataLayer.push({
        'event': eventName,
        currencyCode: this.options.currency,
       'ecommerce': ecommerce,
       'item_id':productData.id
      });
    } else if (typeof gtag != 'undefined') {
      var evt_name = action == 'remove'
        ? 'remove_from_cart'
        : 'add_to_cart';
      gtag('event', evt_name, {items: [productObj]});
    }
  },
  addEcommerceCheckoutStep: function(data) {
    if (typeof window.dataLayer != 'undefined' && this.mode == 'dataLayer') {
      dataLayer.push({
        'event':'checkout',
        currencyCode: this.options.currency,
        'ecommerce': {
          'currencyCode': this.options.currency,
          'checkout': data
        }
      });
    } else if (typeof gtag != 'undefined') {
      var evt_name = data.actionField.step == 1 ? 'begin_checkout' : 'checkout_progress';
      gtag('event', evt_name, {
        items: data.products,
        coupon: typeof data.coupon != 'undefined' ? data.coupon : ''
      });
    }
  },
  addEcommerceCheckoutOption: function(step, option) {
    var data = {
      step: step
    };

    if (option) {
      data.option = option;
    }
    if (typeof window.dataLayer != 'undefined' && this.mode == 'dataLayer') {
      dataLayer.push({
        event: 'checkoutOption',
        currencyCode: this.options.currency,
        ecommerce: {
          currencyCode: this.options.currencyCode,
          checkout_option: {
            actionField: data
          }
        }
      });
    } else if (typeof gtag != 'undefined') {
      gtag('event', 'set_checkout_option', data);
    }
  },

  addEcommercePurchase: function(transactionData, url) {
    var _self = this;
    
    var cb2 = function(code) {
      console.log(code);
      typeof Raven!=='undefined'&&Raven.captureMessage('googleTracker.trackRemarketingPurchase callback called.', {
        level: 'info',
        extra: {
          code: code,
          transactionData: transactionData
        }
      });
      if (url) {
        typeof Raven!=='undefined'&&Raven.captureMessage('Redirecting to: ' + url, {
          level: 'info',
        });
        window.location.replace(url);
      }
    };
    var cb1 = function(code) {
      typeof Raven!=='undefined'&&Raven.captureMessage('googleTracker.addEcommercePurchase callback called', {
        level: 'info',
        extra: {
          code: code,
          transactionData: transactionData
        }
      });
      _self.trackRemarketingPurchase(transactionData.remarketing.reference_ids, transactionData.remarketing.total_value, cb2);
    };

    typeof Raven!=='undefined'&&Raven.captureMessage('googleTracker.addEcommercePurchase is called', {level: 'info', extra: {transactionData: transactionData, url: url}});
    if (typeof window.dataLayer != 'undefined' && this.mode == 'dataLayer') {
      dataLayer.push({
        'event': 'transaction',
        'currencyCode': this.options.currency,
        'ecommerce': {
          currencyCode: this.options.currency,
          'purchase': transactionData
        },
        eventCallback: this.createCallback(cb1)
      });
    } else if (typeof gtag != 'undefined') {
      gtag('event', 'purchase', {
        transaction_id: transactionData.actionField.id,
        value: transactionData.actionField.revenue,
        currency: this.options.currency,
        currency_code: this.options.currency,
        tax: transactionData.actionField.tax,
        shipping: transactionData.actionField.shipping,
        items: transactionData.products,
        event_callback: this.createCallback(cb1)
      });
    } else if (url) {
      document.location = url;
    }
  },

  addEcommerceRefund: function(transaction, admin) {ú
    if (typeof window.dataLayer != 'undefined' && this.mode == 'dataLayer') {
      dataLayer.push({
        'ecommerce': {
          'refund': {
            'actionField': {
              id: transaction
            }
          }
        }
      });
    } else if (typeof gtag != 'undefined') {
      gtag('event', 'refund', {transaction_id: transaction});
    }

    if (admin === true) {
      this.trackEvent('refund', 'Admin Actions', 'Refund', transaction, 0, true);
    }
  },

  addEcommerceProductAddcart: function(productData) {
    this.addEcommerceProductAction(productData, 'add');
  },

  addEcommerceProductRemovecart: function(productData) {
    this.addEcommerceProductAction(productData, 'remove');
  },

  addEcommercePromo: function(id, name, creative, position) {

    if (creative == undefined)
      creative = '';
    if (position == undefined)
      position = '';

    if (typeof window.dataLayer != 'undefined' && this.mode == 'dataLayer') {
      dataLayer.push({
        'event': 'promotion_view',
        'ecommerce': {
          'promoView': {
            'promotions': [
              {
                'id': id,
                'name': name,
                'creative': creative,
                'position': position
              }
            ]
          }
        },
        'promo_id': id,
        'promo_name': name
      });
    } else if (typeof gtag != 'undefined') {
      gtag('event', 'view_promotion', {
        promotions: [
          {
            id: id,
            name: name
          }
        ]
      });
    }
  },

  addEcommercePromoClick: function(name, id, creative, position, url) {
    if (typeof window.dataLayer != 'undefined' && this.mode == 'dataLayer') {
      dataLayer.push({
        event: 'promotionClick',
        ecommerce: {
          promoClick: {
            promotions: [
              {
                id: id,
                name: name,
                creative: creative,
                position: position
              }
            ]
          }
        },
        'promo_id': id,
        'promo_name': name,
        eventCallback: this.createCallback(function() {
          if (url != 'javascript:void(0)') {
            document.location = url;
          }
        })
      });
    } else if (typeof gtag != 'undefined') {
      gtag('event', 'select_content', {
        'promotions': [
          {
            id: id,
            name: name
          }
        ]
      });

      if (url != 'javascript:void(0)') {
        document.location = url;
      }
    }
  },

  getProductObj: function(productData) {
    var obj = {
      id: productData.id,
      name: productData.name,
      category: productData.category,
      brand: typeof productData.brand
        ? productData.brand
        : '',
      variant: typeof productData.variant != 'undefined'
        ? productData.variant
        : '',
      price: productData.price
    };

    if (typeof productData.quantity != 'undefined' && productData.quantity) {
      obj.quantity = parseInt(productData.quantity);
    }

    return obj;
  },

  getCurrency: function() {
    return this.options.currency;
  },

  // Remarketing
  trackRemarketingHomepage: function() {
    if (typeof window.dataLayer != 'undefined' && this.mode == "dataLayer") {
      dataLayer.push({
        'event': 'trackRemarketing',
        'ecomm_pagetype': 'home'
      });
    } else if (typeof window.gtag === 'function') {
      gtag('event', 'trackRemarketing', {
        'ecomm_pagetype': 'home'
      });
    }
  },
  trackRemarketingSearchResults: function(referenceIds, totalValue) {
    if (('isArray' in Array && !Array.isArray(referenceIds)) || !referenceIds) {
      typeof Raven!=='undefined'&&Raven.captureMessage('An array of reference ids must be passed as a first parameter!', {level: 'error'});
      return
    }

    if (!totalValue || !parseFloat(totalValue)) {
      totalValue = 0
    }

    if (!this.options.remarketing || referenceIds.length == 0) {
      return;
    }

    if (typeof window.dataLayer != 'undefined' && this.mode == "dataLayer") {
      dataLayer.push({
        'event': 'trackRemarketing',
        'ecomm_prodid': referenceIds,
        'ecomm_pagetype': 'searchresults',
        'ecomm_totalvalue': totalValue
      });
    } else if (typeof window.gtag === 'function') {
      gtag('event', 'trackRemarketing', {
        'ecomm_prodid': referenceIds,
        'ecomm_pagetype': 'searchresults',
        'ecomm_totalvalue': totalValue
      });
    }
  },
  trackRemarketingProductPage: function(referenceId, totalValue) {
    if (!this.options.remarketing) {
      return;
    }

    if (typeof window.dataLayer != 'undefined' && this.mode == "dataLayer") {
      dataLayer.push({
        'event': 'trackRemarketing',
        'ecomm_prodid': [referenceId],
        'ecomm_pagetype': 'product',
        'ecomm_totalvalue': totalValue
      });
    } else if (typeof window.gtag === 'function') {
      gtag('event', 'trackRemarketing', {
        'ecomm_prodid': [referenceId],
        'ecomm_pagetype': 'product',
        'ecomm_totalvalue': totalValue
      });
    }
  },
  trackRemarketingCategoryPage: function(category) {
    if (!this.options.remarketing) {
      return;
    }

    if (typeof window.dataLayer != 'undefined' && this.mode == "dataLayer") {
      dataLayer.push({
        'event': 'trackRemarketing',
        'ecomm_pagetype': 'category',
        'ecomm_category': category
      });
    } else if (typeof window.gtag === 'function') {
      gtag('event', 'trackRemarketing', {
        'ecomm_pagetype': 'category',
        'ecomm_category': category
      });
    }
  },
  trackRemarketingCart: function(referenceIds, totalValue) {
    if (('isArray' in Array && !Array.isArray(referenceIds)) || !referenceIds) {
      typeof Raven!=='undefined'&&Raven.captureMessage('An array of reference ids must be passed as a first parameter!', {level: 'error'});
      return;
    }

    if (!totalValue || !parseFloat(totalValue)) {
      totalValue = 0
    }

    if (!this.options.remarketing || referenceIds.length == 0) {
      return;
    }

    if (typeof window.dataLayer != 'undefined' && this.mode == "dataLayer") {
      dataLayer.push({
        'event': 'trackRemarketing',
        'ecomm_prodid': referenceIds,
        'ecomm_pagetype': 'cart',
        'ecomm_totalvalue': totalValue
      });
    } else if (typeof window.gtag === 'function') {
      gtag('event', 'trackRemarketing', {
        'ecomm_prodid': referenceIds,
        'ecomm_pagetype': 'cart',
        'ecomm_totalvalue': totalValue
      });
    }
  },
  trackRemarketingPurchase: function(referenceIds, totalValue, callback) {
    if (!this.options.remarketing) {
      return callback();
    }

    if (('isArray' in Array && !Array.isArray(referenceIds)) || !referenceIds) {
      console.error('An array of reference ids must be passed as the first parameter for trackRemarketingPurchase!');
      return callback();
    }

    if (!totalValue || !parseFloat(totalValue)) {
      console.error('A numeric value must be passed as the second parameter for trackRemarketingPurchase!');
      return callback();
    }

    if (typeof window.dataLayer != 'undefined' && this.mode == "dataLayer") {
      dataLayer.push({
        'event': 'trackRemarketing',
        'ecomm_prodid': referenceIds,
        'ecomm_pagetype': 'purchase',
        'ecomm_totalvalue': totalValue,
        'eventCallback': this.createCallback(callback)
      });
    } else if (typeof window.gtag === 'function') {
      gtag('event', 'trackRemarketing', {
        'ecomm_prodid': referenceIds,
        'ecomm_pagetype': 'purchase',
        'ecomm_totalvalue': totalValue,
        'event_callback': this.createCallback(callback)
      });
    } else {
      return callback();
    }
  }
};
