var GoogleAnalyticsTracker = function (options) {
  try{
    this.defaults = {
        trackerCode: '',
        ecommerce: false,
        enabled: true,
        initTracker: false,
        currency: 'HUF'
    };

    this.options = typeof Object.assign != 'undefined' ? Object.assign({}, this.defaults, options) : options;

    if (this.options.initTracker && ( !this.options.trackerCode || !this.options.enabled) ) return undefined;

	   this.init();

    return this;
  } catch(e){
    console.log(e);
  }
};

GoogleAnalyticsTracker.prototype = {

    setEcommerce: function() {
        var _self = this;
        ga('require', 'ec');
        ga('set', 'currencyCode', _self.options.currency);
    },

	init : function () {
      if(this.options.initTracker){
        var _self = this;

        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

        ga('create', _self.options.trackerCode, 'auto');
        ga('require','displayfeatures');

        if (_self.options.ecommerce) _self.setEcommerce();
      }
	},

    trackPageView: function() {
        ga('send', 'pageview');
    },

    trackEvent: function(event_name, category, action, label, value, nonInteraction) {
        if (label == undefined) label = '';
        if (value == undefined) value = 0;

        if (nonInteraction === true) {
            ga("send", "event", category, action, label, value, {nonInteraction: 1});
        }
        else {
            ga("send", "event", category, action, label, value);
        }
    },

    setEcommerceAction: function(action) {
        ga('ec:setAction', action);
    },

    addEcommerceImpression: function(productData, impressionSource) {
        ga('ec:addImpression', {
            id: productData.id,
            name: productData.name,
            category: productData.category,
            variant: '',
            list: impressionSource
        });
    },

    addEcommerceProductAction: function(productData, action, impressionSource) {

        if (impressionSource != undefined) {
            this.addEcommerceImpression(productData, impressionSource);
        }

        ga("ec:addProduct", {
            id: productData.id,
            name: productData.name,
            category: productData.category,
            brand: typeof productData.brand ? productData.brand : '',
            variant: typeof productData.variant != 'undefined' ? productData.variant : '',
            price: productData.price,
            quantity: parseInt(productData.quantity)
        });

        if (action != undefined) this.setEcommerceAction(action);
    },

    addEcommerceCheckoutStep: function(step, option) {
        ga("ec:setAction", "checkout", {
            step: step,
            option: option != undefined ? option : ''
        });
    },

    addEcommercePurchase: function(transactionData, url) {
      var data = typeof transactionData.actionField != 'undefined' ? transactionData.actionField : transactionData;
        if (typeof transactionData.actionField != 'undefined') {
          if (typeof transactionData.products == 'array') {
            for (var i = 0; i < transactionData.products.length; i++) {
              this.addEcommerceProductAction(transactionData.products[i]);
            }
          }
        }
        ga("ec:setAction", "purchase", {
            id: data.id,
            revenue: data.revenue,
            shipping: data.shipping,
            tax: data.tax,
            coupon: data.coupon
        });

        if (url) {
          document.location = url;
        }
    },

    addEcommerceRefund: function(transaction, admin) {

        ga("ec:setAction", "refund", {
            id: transaction
        });

        if (admin === true) {
            this.trackEvent('refound', 'Admin Actions', 'Refund', transaction, 0, true);
        }
    },

    addEcommerceProductAddcart: function(productData) {
        this.addEcommerceProductAction(productData, 'add');
        this.trackEvent('add_to_cart', 'Cart Actions', 'Add to cart', productData.id);
    },

    addEcommerceProductRemovecart: function(productData) {
        this.addEcommerceProductAction(productData, 'remove');
        this.trackEvent('remove_from_cart', 'Cart Actions', 'Remove from cart', productData.id);
    },

    addEcommercePromo: function(id, name, creative, position) {

        if (creative == undefined) creative = '';
        if (position == undefined) position = '';

        ga('ec:addPromo', {
            'id': id,
            'name': name,
            'creative': creative,
            'position': position
        });
    },

    addEcommercePromoClick: function(name) {
        ga('ec:setAction', 'promo_click');
        this.trackEvent('promo_click', 'Internal Promotions', 'Promo click', name);
    },
    getCurrency: function() {
      return this.options.currency;
    }

};
